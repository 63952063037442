import { Formik } from 'formik';
import { signOut } from 'next-auth/react';
import * as Yup from 'yup';
import { t } from '../../generated/i18n';
import { executeGQL } from '../../lib/api';
import { trackEvent } from '../../lib/tracking';
import { Button } from '../common/button';
import { useToast } from '../toast/toast-context';
import { BirthdayField, CheckBox, TextInput } from './fields';

export const CompleteSignUp = ({
  className = '',
  heading = t('finish_signup_heading'),
  description = t('finish_signup_description'),
  fields,
  completeSignUp,
}) => {
  const { toast } = useToast();

  const onAbort = async () => {
    const { hasErrors } = await executeGQL('abortUserSignUp', {});

    if (hasErrors) {
      toast.show(t('genericError'), {
        type: 'error',
        timeout: 0,
      });
    } else {
      signOut({ redirect: false });
    }
  };

  return (
    <>
      <h1 className='mb-2 text-center'>{heading}</h1>
      <p className='mb-6 text-center'>{description}</p>

      <Formik
        initialValues={{
          confirmToc: false,
          birthday: '',
          subscribeNewsletter: false,
          email: '',
        }}
        validationSchema={Yup.object({
          confirmToc: Yup.bool().oneOf([true], t('confirmTocError')),
          birthday: Yup.date()
            .required(t('required'))
            .test('age', t('birthday-error'), function (birthday) {
              const cutoff = new Date();
              cutoff.setFullYear(cutoff.getFullYear() - 18);
              return birthday <= cutoff;
            }),
          subscribeNewsletter: Yup.bool(),
          email: Yup.string().test(
            'len',
            t('required'),
            (val) => (val && val.length > 0) || fields.indexOf('email') < 0,
          ),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);

          const { hasErrors, errors } = await completeSignUp({
            newsletter: values.subscribeNewsletter,
            email: values.email,
          });

          if (hasErrors) {
            setErrors(errors);
            toast.show(t('genericError'), {
              type: 'error',
              timeout: 0,
            });
          } else {
            trackEvent({
              category: 'form',
              action: 'submit',
              label: 'completeRegister',
            });
          }
          setSubmitting(false);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={className}>
            {fields.indexOf('email') >= 0 && (
              <TextInput
                className='mb-8'
                name='email'
                autoComplete='email'
                placeholder={t('email')}
                type='email'
                label={t('email')}
              />
            )}
            {fields.indexOf('birthday') >= 0 && <BirthdayField />}
            {fields.indexOf('toc') >= 0 && (
              <>
                <CheckBox name='confirmToc' label={t('confirmToc')} />
                <CheckBox
                  className='mt-4'
                  name='subscribeNewsletter'
                  label={t('subscribenewsletter')}
                />
              </>
            )}

            <Button
              loading={props.isSubmitting}
              type='submit'
              className='mb-4 mt-8'
              filled
              wide
            >
              {t('complete')}
            </Button>

            <div className='text-center'>
              <a
                role='link'
                className='cursor-pointer text-center text-sm font-bold underline'
                onClick={onAbort}
              >
                {t('abort')}
              </a>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

import { Formik } from 'formik';
import { useContext } from 'react';
import * as Yup from 'yup';
import { t } from '../../generated/i18n';
import { IS_DEFAULT_CHANNEL } from '../../lib/config';
import { trackEvent } from '../../lib/tracking';
import { Button } from '../common/button';
import { ToastContext } from '../toast/toast-context';
import { BirthdayField, CheckBox, TextInput } from './fields';
import { SocialAuthButton } from './social-buttons';

export const RegisterForm = ({
  className = '',
  setOnLogin = null,
  signUp,
  heading = t('register'),
  description = t('registerDescription'),
  children = null,
}) => {
  const { toast } = useContext(ToastContext);
  const schema = {
    firstName: Yup.string().required(t('required')),
    lastName: Yup.string().required(t('required')),
    password: Yup.string()
      .required(t('required'))
      .test('len', t('pwMinLen'), (val) => val && val.length > 4),
    email: Yup.string().email(t('invalidEmail')).required(t('required')),
    confirmToc: Yup.bool().oneOf([true], t('confirmTocError')),
    subscribeNewsletter: Yup.bool(),
    birthday: Yup.date()
      .required(t('required'))
      .test('age', t('birthday-error'), function (birthday) {
        const cutoff = new Date();
        cutoff.setFullYear(cutoff.getFullYear() - 18);
        return birthday <= cutoff;
      }),
  };

  if (!IS_DEFAULT_CHANNEL) {
    schema.company = Yup.string().required(t('required'));
    schema.address = Yup.string().required(t('required'));
  }

  return (
    <>
      <h1 className='mb-2 text-center'>{heading}</h1>
      <p className='mb-6 text-center'>{description}</p>

      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          company: '',
          address: '',
          confirmToc: false,
          subscribeNewsletter: false,
          birthday: '',
        }}
        validationSchema={Yup.object(schema)}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);
          const errors = await signUp(values);
          if (errors) {
            setErrors(errors);
            toast.show(t('genericError'), {
              type: 'error',
              timeout: 0,
            });
          } else {
            trackEvent({
              category: 'form',
              action: 'submit',
              label: 'register',
            });
          }
          setSubmitting(false);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} className={className}>
            {!IS_DEFAULT_CHANNEL && (
              <TextInput
                name='company'
                autoComplete='company'
                placeholder={t('Firma')}
                type='text'
                label={t('Firma')}
              />
            )}
            <TextInput
              name='firstName'
              autoComplete='given-name'
              placeholder={t('firstName')}
              type='text'
              label={t('firstName')}
            />
            <TextInput
              name='lastName'
              autoComplete='family-name'
              placeholder={t('lastName')}
              type='text'
              label={t('lastName')}
            />

            {!IS_DEFAULT_CHANNEL && (
              <TextInput
                name='address'
                autoComplete='address'
                placeholder={t('address')}
                rows={3}
                label={t('address')}
              />
            )}

            <TextInput
              name='email'
              autoComplete='email'
              placeholder={t('email')}
              type='email'
              label={t('email')}
            />
            <TextInput
              id='password'
              name='password'
              type='password'
              autoComplete='password'
              label={t('password')}
            />

            <BirthdayField />

            <CheckBox
              className='mt-8'
              name='confirmToc'
              label={t('confirmToc')}
            />

            <CheckBox
              name='subscribeNewsletter'
              label={t('subscribenewsletter')}
              className='mt-2'
            />

            <Button
              loading={props.isSubmitting}
              type='submit'
              className='mb-3 mt-4 block'
              filled
              wide
            >
              {t('register')}
            </Button>

            {setOnLogin && (
              <a
                role='link'
                className='block cursor-pointer text-center text-sm text-green'
                onClick={setOnLogin}
              >
                {t('alreadyAnAccount')}
              </a>
            )}
            {IS_DEFAULT_CHANNEL && <SocialAuthButton border={!children} />}
            {children && <>{children}</>}
          </form>
        )}
      </Formik>
    </>
  );
};

import { useEffect, useState } from 'react';
import { Error } from '../../components/common/error';
import { t } from '../../generated/i18n';
import { consumeData } from '../../lib/storage';
import { useUser } from '../user-context';
import { CompleteSignUp } from './complete-signup';
import LoginForm from './login-form';
import { RegisterForm } from './register-form';
import { IS_DEFAULT_CHANNEL } from '../../lib/config';

const AuthForm = ({
  loginHeading = t('login'),
  loginDescription = t('loginDescription'),
  className = '',
  children = null,
}) => {
  // @ts-ignore
  const [showRegister, setShowRegister] = useState(false);
  const { credentialsSignIn, credentialsSignUp, user, completeSignUp } =
    useUser();
  const [error, setError] = useState(undefined);

  const authHeading = consumeData('nextAuthHading', loginHeading, true);

  useEffect(() => {
    const queryError = consumeData('queryError', false, true);
    if (queryError) {
      setError(queryError);
    }
  }, []);

  return (
    <div className={`mx-auto max-w-sm md:max-w-md ${className}`}>
      {IS_DEFAULT_CHANNEL && user?.missingFields?.length > 0 ? (
        <CompleteSignUp
          fields={user?.missingFields}
          completeSignUp={completeSignUp}
        />
      ) : (
        <>
          {error &&
            (error === 'OAuthAccountNotLinked' ? (
              <Error
                className='mx-auto'
                title={t('social_auth_not_linked_title')}
                message={t('social_auth_not_linked_message')}
              />
            ) : (
              <Error className='mx-auto' />
            ))}
          {showRegister ? (
            <RegisterForm
              signUp={credentialsSignUp}
              setOnLogin={() => setShowRegister(false)}
            >
              {children}
            </RegisterForm>
          ) : (
            <LoginForm
              signIn={credentialsSignIn}
              description={loginDescription}
              heading={authHeading}
              setOnRegister={() => setShowRegister(true)}
            >
              {children}
            </LoginForm>
          )}
        </>
      )}
    </div>
  );
};

export default AuthForm;
